import { Grid, Title } from "ui";

type Props = {
  address: string;
};

export const Address = ({ address }: Props) => (
  <Grid item xs={12} container alignItems="center" id="aa" columnSpacing={1}>
    <Grid item xs="auto">
      <Title>
        {address.substring(0, length + 5)}...
        {address.substring(address.length - 5)}
      </Title>
    </Grid>
  </Grid>
);

export default Address;
