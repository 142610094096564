import { EntityName, Metadata, Tags } from "components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  BitcoinIcon,
  Button,
  CheckCircleIcon,
  ContentCopyIcon,
  EthereumIcon,
  Grid,
  Label,
  StarBorderIcon,
  StarIcon,
  Subtitle,
  Tag,
} from "ui";
import Address from "./Address";
import RiskSummary from "./RiskSummary";
import { ReportMeta } from "types";
import * as S from "../styled";
import { formatCryptoCurrencyValue } from "utils";
import { AppContext } from "contexts";
import { useContext, useEffect, useRef, useState } from "react";
import { PortfolioMenu } from "./PortfolioMenu";
import { NotificationsPopover } from "./NotificationsPopover";

type Props = Pick<
  ReportMeta,
  "entityName" | "entityTag" | "entitySubTag" | "controllerTag" | "controllerType" | "controllerSubType" | "address"
> & {
  reportType: "btc" | "eth";
  balanceCrypto: number;
  balanceFiat: number;
  highRiskCount: number;
  labels: string[];
  addToPortfolio: (portfolioId: number) => void;
  removeFromPortfolio: (portfolioId: number, address: string) => void;
  isInPortfolio: boolean;
};

export const ReportTitle = ({
  entityName,
  entityTag,
  entitySubTag,
  controllerTag,
  controllerType,
  controllerSubType,
  address,
  reportType,
  balanceCrypto,
  highRiskCount,
  labels,
  addToPortfolio,
  removeFromPortfolio,
}: Props) => {
  const { entityTypes, portfolios } = useContext(AppContext);
  const [copyText, setCopyText] = useState("Copy");
  const [animateCopy, setAnimateCopy] = useState(false);
  const [animateAddToPortfolio, setAnimateToPortfolio] = useState(false);
  const [portfolioButtonText, setPortfolioButtonText] = useState<string>("Added to Portfolio");
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isPortfolioMenuOpen, setIsPortfolioMenuOpen] = useState(false);
  const intialPortfolioWithAddress = portfolios.filter((portfolio) =>
    portfolio.data.some((data) => data.addressInfo.address === address),
  );

  const [portfoliosWithAddress, setPortfoliosWithAddress] = useState(intialPortfolioWithAddress);
  const portfolio = portfoliosWithAddress.length > 0 ? "in-portfolio" : "no-portfolio";
  const [portfioButtonStatus, setPortfioButtonStatus] = useState<
    "no-portfolio" | "added-to-portfolio" | "removed-from-portfolio" | "in-portfolio"
  >(portfolio);
  const [addressPortfolioName, setAddressPortfolioName] = useState<string | null>(null);
  const [portolioMenuAnchor, setPortolioMenuAnchor] = useState<null | HTMLElement>(null);
  const anyRiskyTag = entityTypes.some((type) => {
    return (
      [entityTag, entitySubTag, controllerType, controllerSubType].includes(type.name) &&
      ["HIGH", "VERY_HIGH"].includes(type.risk)
    );
  });

  const ownerTags = [];
  if (entityTag) {
    ownerTags.push({ type: entityTag });
  }
  if (entitySubTag) {
    ownerTags.push({ type: entitySubTag });
  }

  const controllerTags = [];
  if (controllerType) {
    controllerTags.push({ type: controllerType });
  }
  if (controllerSubType) {
    controllerTags.push({ type: controllerSubType });
  }

  const handleAddAddressToPortfolio = (portfolioID: number, portfolioName: string) => {
    setIsPortfolioMenuOpen(false);
    setPortfioButtonStatus("added-to-portfolio");
    const portfolio = portfolios.find((portfolio) => portfolio.id === portfolioID)!;
    const updatedPortfoliosWithAddress = [...portfoliosWithAddress, portfolio];
    setPortfoliosWithAddress(updatedPortfoliosWithAddress);
    addToPortfolio(portfolioID);
    setAddressPortfolioName(portfolioName);
  };

  const handleRemoveAddressFromPortfolio = (portfolioID: number, portfolioName: string) => {
    setIsPortfolioMenuOpen(false);
    setPortfioButtonStatus("removed-from-portfolio");
    const updatedPortfoliosWithAddress = portfoliosWithAddress.filter((portfolio) => portfolio.id !== portfolioID);
    setPortfoliosWithAddress(updatedPortfoliosWithAddress);
    setAddressPortfolioName(portfolioName);
    removeFromPortfolio(portfolioID, address);
  };

  useEffect(() => {
    if (portfioButtonStatus === "added-to-portfolio") {
      setPortfolioButtonText(`Added to "${addressPortfolioName}"`);
      setAnimateToPortfolio(true);
      const timeout = setTimeout(() => {
        setPortfioButtonStatus("in-portfolio");
      }, 1500);

      return () => clearTimeout(timeout);
    }
    if (portfioButtonStatus === "removed-from-portfolio") {
      setPortfolioButtonText(`Removed from "${addressPortfolioName}"`);
      setAnimateToPortfolio(true);
      const timeout = setTimeout(() => {
        if (portfoliosWithAddress.length === 0) {
          setPortfioButtonStatus("no-portfolio");
        } else {
          setPortfioButtonStatus("in-portfolio");
        }
      }, 1500);

      return () => clearTimeout(timeout);
    }

    if (portfioButtonStatus === "in-portfolio" || portfioButtonStatus === "no-portfolio") {
      setPortfolioButtonText("Add to Portfolio");
    }
  }, [portfioButtonStatus]);

  const getPortfolioButtonIcon = () => {
    switch (portfioButtonStatus) {
      case "no-portfolio":
        return <StarBorderIcon />;
      case "in-portfolio":
        return <StarIcon color="primary" />;
      case "added-to-portfolio":
        return <CheckCircleIcon color="success" />;
      case "removed-from-portfolio":
        return <CheckCircleIcon color="warning" />;
    }
  };

  const getPortfolioButtonColor = () => {
    switch (portfioButtonStatus) {
      case "no-portfolio":
        return "secondary";
      case "in-portfolio":
        return "primary";
      case "added-to-portfolio":
        return "success";
      case "removed-from-portfolio":
        return "warning";
    }
  };

  const getCopyColor = () => {
    switch (copyText) {
      case "Copy":
        return "secondary";
      case "Address Copied":
        return "success";
    }
  };

  useEffect(() => {
    // After the animation duration (1s), reset shouldAnimate to false
    const animationTimeout = setTimeout(() => {
      setCopyText("Copy");
    }, 1500);

    return () => clearTimeout(animationTimeout);
  }, [copyText]);

  return (
    <Grid item container flexWrap="nowrap" columnGap={3} rowGap={3} direction="column">
      <Grid container flexWrap="nowrap">
        <Grid xs="auto" paddingRight={3}>
          <S.IconWrapper>{reportType === "btc" ? <BitcoinIcon /> : <EthereumIcon />}</S.IconWrapper>
        </Grid>
        <Grid container direction="column" flexWrap="nowrap">
          <Subtitle variant="small">Address:</Subtitle>
          <Address address={address} />
          <Grid container columnGap={1}>
            {labels.map((label) => (
              <Tag key={label} label={label} backgroundColor="lightGray" color="darkGray" />
            ))}
          </Grid>
        </Grid>
        <Grid item container flexWrap="nowrap" xs="auto" columnGap={1} alignItems={"center"}>
          <NotificationsPopover address={address} />
          <Grid>
            <CopyToClipboard text={address}>
              <Button
                icon={<ContentCopyIcon />}
                buttonTheme="cancel"
                variant="outlined"
                color={getCopyColor()}
                animateOnChange={animateCopy}
                onClick={() => {
                  setCopyText("Address Copied");
                  setAnimateCopy(true);
                }}
              >
                {copyText}
              </Button>
            </CopyToClipboard>
          </Grid>
          <Grid>
            <Button
              ref={buttonRef}
              icon={getPortfolioButtonIcon()}
              variant="outlined"
              color={getPortfolioButtonColor()}
              animateOnChange={animateAddToPortfolio}
              onClick={(e) => {
                setIsPortfolioMenuOpen(true);
                setPortolioMenuAnchor(e.currentTarget);
              }}
            >
              {portfolioButtonText}
            </Button>
            <PortfolioMenu
              reportAddress={address}
              removeFromPortfolio={(portfolioID: number, portfolioName: string) => {
                handleRemoveAddressFromPortfolio(portfolioID, portfolioName);
              }}
              onAddedToPortfolio={(portfolioID: number, portfolioName: string) => {
                handleAddAddressToPortfolio(portfolioID, portfolioName);
              }}
              open={isPortfolioMenuOpen}
              anchor={portolioMenuAnchor}
              onClose={() => setIsPortfolioMenuOpen(false)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item container rowGap={2} paddingLeft="80px" direction="column">
        <Grid item>
          <Metadata
            columnGap={6}
            list={[
              {
                title: "Total Balance",
                width: "auto",
                value: `${formatCryptoCurrencyValue(balanceCrypto, reportType)} ${reportType.toUpperCase()}`,
              },
              {
                title: "Owner",
                width: "auto",
                value: (
                  <Grid container columnGap={1}>
                    <Grid>
                      <EntityName name={entityName || "Unknown"} />
                    </Grid>
                    <Grid>
                      <Label>{ownerTags.length > 0 && <Tags tags={ownerTags} short />}</Label>
                    </Grid>
                  </Grid>
                ),
              },
              {
                title: "Controller",
                width: "auto",
                value: (
                  <Grid container columnGap={1}>
                    <Grid>
                      <EntityName name={controllerTag || "Unknown"} />
                    </Grid>
                    <Grid>
                      <Label>{controllerTags.length > 0 && <Tags tags={controllerTags} short />}</Label>
                    </Grid>
                  </Grid>
                ),
              },
            ]}
          />
        </Grid>
        <Grid>
          <RiskSummary highRiskCount={highRiskCount} mediumRiskCount={0} addressRisk={anyRiskyTag} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportTitle;
