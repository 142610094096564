import React, { useContext, useState } from "react";
import { Grid, SearchIcon } from "ui";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useNavigate } from "react-router-dom";
import AppContext from "contexts/AppContext";
import { Entity, Hack, Sanction } from "types";
import { InputAdornment } from "@mui/material";
import SearchResult, { SearchItem, SearchResultTypes } from "./SearchResult";
import AuthContext from "contexts/Auth0Context";
import { isBitcoinAddress } from "utils";

import * as S from "../styled";

type Props = {
  placeholder: string;
  onChange: (value: string, reason?: string) => void;
  onFocus?: () => void;
  onBlur?: (searchInProgress: boolean) => void;
  value: string;
  options: string[];
  name?: string;
  isSmallScreen?: boolean;
  animate?: boolean;
  onSearchCancel?: () => void;
};

type SearchResult = {
  entities: Entity[];
  hacks: Hack[];
  sanctions: Sanction[];
  address?: {
    address: string;
    type: "eth" | "btc";
  };
};

export const SearchBar = ({ onChange, onSearchCancel, onFocus, onBlur, animate = true }: Props) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [resultsCount, setResultsCount] = useState(0);
  const [searchResultOpen, setIsSearchResultOpen] = useState(false);
  const [blurTimeoutId, setBlurTimeoutId] = useState<number>(-1);

  const appContext = useContext(AppContext);
  const authContext = useContext(AuthContext);
  const [searchResult, setSearchResult] = useState<SearchResult>({
    entities: [],
    hacks: [],
    sanctions: [],
  });
  const [focusIndex, setFocusIndex] = useState(-1);
  const [isUsingKeyboard, setIsUsingKeyboard] = useState(false);

  const entities = appContext.entities.filter((entity) => entity.description !== undefined);

  const restoreScroll = () => {
    const layoutElement = document.getElementById("landing-page");
    if (layoutElement) {
      layoutElement.style.overflow = "auto"; // Or 'scroll'
    }
  };

  const resetSearch = () => {
    setSearchResult({
      entities: [],
      hacks: [],
      sanctions: [],
    });
    setSearchTerm("");
    setIsSearchResultOpen(false);
    restoreScroll();
  };

  const hideScroll = () => {
    const layoutElement = document.getElementById("landing-page");
    if (layoutElement) {
      layoutElement.style.overflow = "hidden";
    }
  };

  const handleSearch = (value: string) => {
    hideScroll();
    setIsSearchResultOpen(true);
    if (!value) {
      resetSearch();
      return;
    }
    setFocusIndex(-1); // TODO

    const bitcoinAddressRegex = "^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,62}$";
    const isValidBitcoinAddress = new RegExp(bitcoinAddressRegex).test(value);
    const ethereumAddressRegex = "^0x[a-fA-F0-9]{40}$";
    const isValidEthereumAddress = new RegExp(ethereumAddressRegex).test(value);
    let address = "";
    let addressType: "eth" | "btc" | null = null;
    if (isValidBitcoinAddress) {
      address = value;
      addressType = "btc";
    } else if (isValidEthereumAddress) {
      address = value;
      addressType = "eth";
    }
    setSearchTerm(value);
    const foundEntities = entities.filter((entity) => entity.name.toLowerCase().includes(value.toLowerCase()));

    const foundHacks = appContext.events.hacks.filter((hack) => {
      return hack.Entity.toLowerCase().includes(value.toLowerCase());
    });

    const foundSanctions = appContext.events.sanctions.filter((sanction) => {
      return sanction.Subject.toLowerCase().includes(value.toLowerCase());
    });
    let resultCount: number;
    const searchResult: SearchResult = {
      entities: foundEntities.slice(0, 5),
      hacks: foundHacks.slice(0, 5),
      sanctions: foundSanctions.slice(0, 5),
    };
    resultCount = foundEntities.length + foundHacks.length + foundSanctions.length;
    if (address && addressType) {
      searchResult.address = {
        address: address,
        type: addressType,
      };
      resultCount++;
    }

    setSearchResult(searchResult);
    setResultsCount(resultCount);
  };

  const showAddress = (address: string, type: "eth" | "btc") => {
    resetSearch();
    navigate(`/report/${type}/${address}`);
  };

  const showEntity = (entityName: string) => {
    resetSearch();
    const entityDetail = entities.find((entity) => entity.name === entityName) as Entity;
    if (entityDetail) {
      navigate(`/entities/${entityDetail.name}`);
    }
  };

  const showHack = (hackId: string) => {
    resetSearch();
    const hack = appContext.events.hacks.find((hack) => hack.Entity === hackId);
    if (hack) {
      navigate(`/events/hacks/${hack.id}`);
    }
  };

  const showSanction = (sanctionID: string) => {
    resetSearch();
    const sanction = appContext.events.sanctions.find((sanction) => sanction.Subject === sanctionID);
    if (sanction) {
      navigate(`/events/sanctions/${sanction.id}`);
    }
  };

  const handleSearchItemOpen = (id: string, type: SearchResultTypes) => {
    clearTimeout(blurTimeoutId);
    switch (type) {
      case "entity":
        showEntity(id);
        break;
      case "hack":
        showHack(id);
        break;
      case "sanction":
        showSanction(id);
        break;
      case "address":
        if (searchResult.address) {
          showAddress(id, searchResult.address.type);
        }
        break;
      case "search_history": {
        const addressType = isBitcoinAddress(id) ? "btc" : "eth";
        showAddress(id, addressType);
        break;
      }
    }
  };

  const resultsItems: SearchItem[] = [
    ...searchResult.entities.map((item) => ({
      id: item.name,
      name: item.name,
      type: "entity" as SearchResultTypes,
    })),
    ...searchResult.hacks.map((item) => ({
      id: item.id.toString(),
      name: item.Entity,
      type: "hack" as SearchResultTypes,
    })),
    ...searchResult.sanctions.map((item) => ({
      id: item.id.toString(),
      name: item.Subject,
      type: "sanction" as SearchResultTypes,
    })),
  ];

  if (searchResult.address) {
    resultsItems.push({
      id: searchResult.address.address,
      name: searchResult.address.address,
      type: "address",
    });
  }

  if (authContext.user.lastUserSearches) {
    resultsItems.push(
      ...authContext.user.lastUserSearches.map((item) => ({
        id: item,
        name: item,
        type: "search_history" as SearchResultTypes,
      })),
    );
  }

  const handleKeyDown = (event: any) => {
    setIsUsingKeyboard(true);
    if (event.key === "ArrowDown") {
      setFocusIndex((f) => Math.min(f + 1, resultsCount - 1));
    } else if (event.key === "ArrowUp") {
      setFocusIndex((f) => Math.max(f - 1, 0));
    } else if (event.key === "Enter" && focusIndex !== -1) {
      // // Action when Enter is pressed
      const focusedItem = resultsItems[focusIndex];
      handleSearchItemOpen(focusedItem.id, focusedItem.type);
    } else if (event.key === "Escape") {
      resetSearch();
    }
  };
  const onMouseEnter = () => {
    if (isUsingKeyboard) {
      // Temporarily disable keyboard focus styling when using the mouse
      setFocusIndex(-1);
    }
  };

  const onMouseLeave = () => {
    if (isUsingKeyboard) {
      // Optionally restore focus index when the mouse leaves
      // setFocusIndex(previousFocusIndex);
    }
  };

  return (
    <S.SearchBarContainer isAtTop={animate} searchResultOpen={searchResultOpen} isSmallScreen={true}>
      <Grid
        overflow-y="scroll"
        id="containercontainer"
        direction="column"
        height="100%"
        width="100%"
        flex-wrap="nowrap"
      >
        <Grid item container justifyContent="center">
          <S.Input
            label=""
            placeholder="Search for an entity, event, or cryptocurrency address..."
            value={searchTerm}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange(event.target.value);
              handleSearch(event.target.value);
            }}
            sx={(theme) => ({
              maxWidth: { xs: "90%", md: "640px" },
              "& .MuiOutlinedInput-root": {
                // Default state styles
                "& fieldset": {
                  borderColor: theme.colors.primary,
                  opacity: 0.3, // replace with your default color: ;
                },
                // Hover state styles
                "&:hover fieldset": {
                  borderColor: theme.colors.primary,
                  opacity: 1, // replace with your hover color
                },
                // Focus state styles
                "&.Mui-focused fieldset": {
                  opacity: 1,
                },
              },
            })}
            InputProps={{
              onKeyDown: handleKeyDown,
              onFocus: () => {
                onFocus && onFocus();
                hideScroll();
                setIsSearchResultOpen(true);
              },
              onBlur: () => {
                {
                  onBlur && onBlur(!!searchTerm);
                  const timeoutId = setTimeout(() => {
                    resetSearch();
                  }, 200); // 200ms delay
                  setBlurTimeoutId(timeoutId as unknown as number);
                }
              },
              endAdornment: searchTerm ? (
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setSearchTerm("");
                    resetSearch();
                    onSearchCancel && onSearchCancel();
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <CancelOutlinedIcon sx={{ height: "18px", width: "18px" }} />
                </InputAdornment>
              ) : undefined,
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    borderRadius: "12px",
                    height: "40px",
                    width: "40px",
                    justifyContent: "center",
                    marginLeft: "-5px",
                    backgroundColor: "#DA016D",
                    color: "white",
                  }}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {searchResultOpen && (
          <SearchResult
            focusedIndex={focusIndex}
            resultsItems={resultsItems}
            hacks={searchResult.hacks}
            entities={searchResult.entities}
            sanctions={searchResult.sanctions}
            address={searchResult.address}
            onSearchResultOpen={handleSearchItemOpen}
            searcHistory={authContext.user.lastUserSearches}
            onCloseResult={() => {
              resetSearch();
            }}
            onClickOutside={() => {
              resetSearch();
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        )}
      </Grid>
    </S.SearchBarContainer>
  );
};

export default SearchBar;
