import { formatDate, formatCryptoCurrencyValue, formatFiatValue } from "utils";
import { Grid, Link as UILink, Tooltip, InfoIcon, ColumnHeaderParams } from "ui";

import type { Columns } from "ui";
import { Address } from "components";
import { GridCellParams, GridRenderCellParams, GridValueFormatterParams } from "@mui/x-data-grid";
import { TransactionWithSenderReceiver } from "./TransactionTable";

const renderAddressHeader = (params: ColumnHeaderParams) => (
  <Grid container justifyContent="center" className=" MuiDataGrid-columnHeaderTitle">
    <Grid item>{params.colDef.headerName}</Grid>
    <Grid item alignItems="center" sx={{ display: "flex" }}>
      <Tooltip title="Grey shading corresponds to the subject address">
        <InfoIcon sx={{ height: "16px", color: "lightGrey" }} />
      </Tooltip>
    </Grid>
  </Grid>
);

const getColumns = (coin: "eth" | "btc" | "erc20", originAddress: string, originEntityName: string): Columns => {
  return [
    {
      field: "sender",
      headerName: "Sender",
      renderHeader: renderAddressHeader,
      flex: 1,
      cellClassName: (params: GridCellParams<string>) => (params.value === originAddress ? "highlight" : ""),
      renderCell: ({ value, row }: GridRenderCellParams<string, TransactionWithSenderReceiver & { id: number }>) => {
        const reportCoin = coin === "erc20" ? "eth" : coin;
        return (
          <Grid container columnGap={2}>
            <Grid>
              <Address link={`/report/${reportCoin}/${value}`} address={row.sender.toString()} variant="short" />
            </Grid>

            <Grid>{value === originAddress ? originEntityName : row.entityTag || row.controllerTag || "Unknown"}</Grid>
          </Grid>
        );
      },
    },
    {
      field: "receiver",
      headerName: "Receiver",
      flex: 1,
      renderHeader: renderAddressHeader,
      cellClassName: (params: GridCellParams<string>) => (params.value === originAddress ? "highlight" : ""),
      renderCell: ({ row, value }: GridRenderCellParams<string, TransactionWithSenderReceiver & { id: number }>) => {
        const reportCoin = coin === "erc20" ? "eth" : coin;
        return (
          <Grid container columnGap={2}>
            <Grid>
              <Address link={`/report/${reportCoin}/${value}`} address={value ?? ""} variant="short" />
            </Grid>
            <Grid>
              {row.receiver === originAddress ? originEntityName : row.entityTag || row.controllerTag || "Unknown"}
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      align: "left",
      width: 150,
      valueFormatter: ({ value }: GridValueFormatterParams<number>) => {
        return formatCryptoCurrencyValue(value, coin);
      },
    },
    {
      field: "value",
      headerName: "Value ($)",
      align: "left",
      width: 150,
      valueFormatter: ({ value }: GridValueFormatterParams<number>) => formatFiatValue(value, coin),
    },

    {
      field: "date",
      headerName: "Date",
      width: 120,
      valueFormatter: ({ value }: GridValueFormatterParams<string>) => (value ? formatDate(value) : null),
    },
    {
      field: "curHop",
      headerName: "Hops",
      width: 80,
    },

    {
      field: "hash",
      headerName: "Hash",
      width: 80,
      type: "string",
      renderCell: ({ value, row }: GridRenderCellParams<string, TransactionWithSenderReceiver & { id: number }>) => (
        <UILink
          href={coin === "btc" ? `https://www.blockchain.com/${coin}/tx/${value}` : `https://etherscan.io/tx/${value}`}
          target="_blank"
          rel="noreferrer"
        >
          {row.hash.slice(0, 5)}...
        </UILink>
      ),
    },
  ];
};

export default getColumns;
